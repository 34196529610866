.banner-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  text-align: center;
  font-weight: 300;
}

.banner-text {
  font-size: 32pt;
  line-height: 36pt;
  margin: 0px 0px 20px 0px;
}

.banner-button {
  color: #fff;
  border: 2px solid #fff;
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 13pt;
  padding: 10px 20px;
  transition: all .5s;
}

.banner-button:hover {
  background-color: #fff;
  border-color: #fff;
  color: #191919;
}

#tsparticles {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}


/* Mobile */
@media only screen and (max-width: 767px) {

  /* Banner */
  .banner-wrap {
    top: 40%;
    left: 15%;
  }
  .banner-text {
    font-size: 18pt;
  }
  .banner-button {
    color: #fff;
    padding: 10px 20px;
    font-size: 13pt;
  }
}

/* iPad */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-wrap {
    top: 40%;
    left: 20%;
  }

}

/* iPad Pro */
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  
}