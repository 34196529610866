html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Raleway';
  background-color: rgb(29, 29, 29);
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

img {
  aspect-ratio: attr(width) / attr(height);
}

@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }
}
