#projects {
  background-color: rgb(25, 25, 25);
  padding: 100px 0px 130px 0px;
}

.header {
  color: #fff;
}

.panels {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 50px;
  grid-column-gap: 50px; */
}

@media only screen and (max-width: 767px) {
  #projects {
    height: initial;
  }
  .panels {
    display: block;
  }

  .panel.open {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  #projects {
    height: initial;
  }
  .panels {
    display: block;
  }
  .panel {
    overflow: hidden;
    margin-left: 0;
    margin-bottom: 12px;
    height: 200px;
  }
  .panel.open {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1024px) {
}
