.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 45%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.carousel-control-prev {
  margin: 0;
  top: 80%;
  background-color: #312e2e52 !important;
}

.carousel-control-next {
  margin: 0;
  top: 80%;
  background-color: #312e2e52 !important;
}
