/* Main card */
.project-card {
  margin: 3em;
  text-align: center;
  width: 25%;
}

/* Modal details */
.project-details {
  margin: 12px auto;
  text-align: start;
  width: 90%;
}

/* Carousel settings */
.carousel-control-next {
  background-color: #66666652;
  height: 20%;
  width: 10%;
  margin: auto;
}

.carousel-control-prev {
  background-color: #66666652;
  height: 20%;
  width: 10%;
  margin: auto;
}

.media-buttons {
  display: flex;
  margin-left: 30px;
  margin-bottom: 16px;
}

.media-buttons a {
  font-size: 16px;
  margin: 8px;
  border: 1px solid #212121;
  border-radius: 8px;
  padding: 0px 16px;
  text-decoration: none;
  text-transform: capitalize;
  background-color: #fff;
  transition: all 0.5s;
  color: #212121;
}

.media-buttons a:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: #212121;
}

button {
  font-size: 16px;
  margin: 8px;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 0px 16px;
  text-decoration: none;
  text-transform: capitalize;
  background-color: #191919;
  transition: all 0.5s;
  color: #fff;
}

button:hover {
  color: #191919;
  border: 1px solid #fff;
  background-color: #fff;
}

/* Main card */
.card-wrapper {
  margin: 0 auto;
  position: relative;
}

.card-title {
  font-size: 32px;
  text-transform: uppercase;
  text-shadow: #fff 1px 1px, #191919 2px 2px, #fff 3px 3px, #191919 4px 4px;
}

.card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: #fff;
}

.card-wrapper img {
  display: block;
  width: 100%;
  height: auto;
}

.card-overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #191919;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.card-wrapper:hover .card-overlay {
  bottom: 0;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .project-card {
    margin: 28px 0;
  }

  .panels {
    width: auto;
  }

  .card-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 375px) {
  .project-card {
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .project-card {
    width: auto;
  }

  .card-title {
    font-size: 28px;
  }
}
