#contact {
  padding: 100px 0px 130px 0px;
  color: #fff;
  background-color: rgb(25, 25, 25);
}

#contact-container {
  width: 100%;
  text-align: center;
  display: block;
}

#contact-container p {
  color: #edc712;
}

#contact-row {
  text-align: center;
}
.contact-left {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 2;
  grid-row: 2 / span 2;
}
form {
  width: 40%;
  padding: 32px;
}

form input {
  width: 100%;
  margin-bottom: 4px;
}

form textarea {
  padding: 8px;
  border: none;
  background-color: rgb(33, 33, 33);
  color: #fff;
  width: 100%;
  height: 150px;
}
form button {
  background-color: rgb(33, 33, 33);
  border: 1px solid #fff;
  color: #fff;
  float: right;
  padding: 10px 20px;
  margin: 5px 0 0 0;
  transition: all 0.5s;
  border-radius: 4px;
}

form button:hover {
  background-color: rgb(60, 60, 60);
  color: #fff;
}

form p {
  margin-top: 8px;
}

label {
  display: block;
  text-align: left;
  margin: 4px;
}

input {
  padding: 8px;
  width: 40%;
  border: none;
  background-color: rgb(33, 33, 33);
  color: #fff;
}

@media only screen and (max-width: 767px) {
  form {
    width: 100%;
  }

  .footer-container a {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  #contact {
    padding: 0;
  }
}
