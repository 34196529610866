.footer-container {
  background-color: rgb(25, 25, 25);
}

.social-links {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.social-links li {
  margin: 24px;
  list-style: none;
}

.social-links i {
  padding: 8px;
  color: #fff;
  transition: all 0.5s;
}

.github:hover {
  color: hsla(0, 0%, 100%, 0.7);
  transform: scale(1.2);
}

.twitter:hover {
  transform: scale(1.2);
  color: #00a5ff;
}

.linkedin:hover {
  transform: scale(1.2);
  color: #0078ba;
}

.save:hover {
  transform: scale(1.2);
  color: rgb(243, 118, 118);
}
