/* About */

/* Hex small */
.hex {
  position: relative;
  margin: 1em auto;
  width: 52px;
  height: 83px;
  border-radius: 0.5em;
  opacity: 1;
  background-color: #edc712;
  transition: opacity 0.5s;
}
.hex:before,
.hex:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.hex:before {
  transform: rotate(60deg);
}
.hex:after {
  transform: rotate(-60deg);
}

/* Hex big */
.hex-big {
  position: relative;
  margin: 1em auto;
  width: 80px;
  height: 132px;
  border-radius: 0.5em;
  opacity: 1;
  background-color: #edc712;
  transition: opacity 0.5s;
}
.hex-big:before,
.hex-big:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.hex-big:before {
  transform: rotate(60deg);
}
.hex-big:after {
  transform: rotate(-60deg);
}

/*Hex medium */
.hex-medium {
  position: relative;
  margin: 1em auto;
  width: 65px;
  height: 105px;
  border-radius: 0.5em;
  opacity: 1;
  background-color: #edc712;
  transition: opacity 0.5s;
}
.hex-medium:before,
.hex-medium:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}
.hex-medium:before {
  transform: rotate(60deg);
}
.hex-medium:after {
  transform: rotate(-60deg);
}

.icon {
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 30pt;
}

.icon-big {
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 60pt;
}

.icon-medium {
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 45pt;
}

.redux {
  color: #fff;
}

.redux-medium {
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  color: #fff;
  width: 45pt;
}

#color1 {
  background-color: #edc712;
}

#about {
  padding: 130px 0px 170px 0px;
  background-color: rgb(25, 25, 25);
  color: #fff;
}

section .container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 10px;
  width: 100%;
}

.header {
  font-size: 30pt;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}

.description {
  text-align: center;
  font-weight: 300;
  margin-top: 120px;
  align-items: center;
}

.profile-image {
  height: 270px;
  width: 220px;
  border-radius: 10%;
}

.socials {
  margin-left: 50px;
}

.socials a {
  color: #edc712;
  font-size: 32px;
  margin-right: 32px;
  transition: all 0.4s;
}

.socials a:hover {
  color: #fff;
}

.style-cv {
  width: 50px;
  margin-left: -8px;
}

@media only screen and (max-width: 767px) {
  /* About */
  
  #about {
    padding: 50px 0px 40px 0;
  }

  .header {
    font-size: 25pt;
  }
  .row {
   display: block;
  }
  /* .description p {
    margin-top: 10%; */
  /* padding: 14px; */
  /* }  */

  section .container {
    padding: 0;
    max-width: 1000px;
  }
}

@media only screen and (max-width: 1024px) {
  #about {
    padding: 0;
  }
  .about-text {
    padding: 64px;
  }
  .description p {
    font-size: 10pt;
    /* margin-top: 3em; */
    /* padding: 14px; */
  }
}

@media only screen and (max-width: 375px) {

  .hex-big {
    width: 40px;
    height: 63px;
  }

  .hex-medium {
    width: 40px;
    height: 63px;
  }
  
  .hex {
    width: 40px;
    height: 63px;
  }

  .hex-wrap {
    margin-left: 2em;
  }

  .icon-big {
    font-size: 30pt;
  }

  .icon-medium {
    font-size: 30pt;
  }

  .redux-medium {
    width: 30pt;
  }

  .row-skills {
    margin-right: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 320px) {
  .row {
    display: block;
  }

  .banner-wrap {
    left: 7%;
  }
}
